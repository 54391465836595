<script>
import {mapGetters} from 'vuex';
  export default {
       data() {
      return {
        userData: JSON.parse(localStorage.getItem("userData")),
      };
    },
    methods: {

      $Can(permissionName) {
        if(this.userData.role==="supermanager"){
          return true;
        }else{
            return localStorage.getItem("userPerms").includes(permissionName)?true:false;
        }      
      },
       $CanViewMenu(item) {
        if(this.userData.role==="supermanager"){
          return true;
        }else{
            return  this.$Can(item.Permission);
        }      
      },
    },
    
  };
</script>